import cn from 'classnames';
import ReactPlayer from 'react-player';
import React, { useMemo } from 'react';

import { cls } from '@mssgme/helpers';
import { AudioBlockKind } from '@mssgme/shared';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase, useBlockHorizontalInsetsPadding } from '../BlockBase';

import styles from './Audio.scss';

export default function Audio({ block, block: { url }, insets, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const hasAudio = ReactPlayer.canPlay(url);
    const blockInsets = useBlockHorizontalInsetsPadding(insets, theme.padding === 'yes');

    return (
        <BlockBase insets={blockInsets} {...cls(rest)}>
            <div className={cn(styles.root, { [styles.hasAudio]: hasAudio })} style={style}>
                {hasAudio && (
                    <ReactPlayer
                        className={cn({ [styles.player]: true, [styles.mixcloud]: url.includes('mixcloud.com') })}
                        url={url}
                        width="100%"
                        height="100%"
                        config={{
                            mixcloud: {
                                options: {
                                    hide_cover: false,
                                    hide_tracklist: false,
                                    hide_artwork: false,
                                },
                            },
                        }}
                    />
                )}
            </div>
        </BlockBase>
    );
}

Audio.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Audio.defaultProps = BlockBase.defaultProps;

Audio.kind = AudioBlockKind;
