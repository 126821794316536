import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';

import { ImageGalleryBlockKind } from '@mssgme/shared';
import { cls, isLightColor } from '@mssgme/helpers';
import { useBooleanHandler, useForceUpdate } from '@mssgme/hooks';
import { LandingsPropTypes, Swiper } from '@mssgme/ui';
import { useBlockTheme, useContrastingPageColor } from '../../../hooks';
import { LightBox, useDoubleNestedInsets } from '../../../UI';
import { BlockBase } from '../BlockBase';
import { ImageSlide } from './ImageSlide';

import styles from './ImageGallery.scss';

export const useThrottledCallback = (throttleMs = 1000) => {
    const [callback, setCallback] = useState(null);
    const setter = useCallback((fn) => setCallback(() => fn), []);

    useEffect(() => {
        if (!callback) {
            return;
        }

        const timer = setTimeout(callback, throttleMs);

        return () => {
            clearTimeout(timer);
        };
    }, [callback, throttleMs]);

    return setter;
};

const fade = { crossFade: true };
const lazy = { loadPrevNext: true, preloaderClass: styles.preloader, loadedClass: styles.loaded };

export default function ImageGallery({ block, insets, sortable, ...rest }) {
    const [ref, { width: rawWidth }] = useMeasure();
    const [swiper, setSwiper] = useState(null);
    const [currentLightBoxSlideIndex, setCurrentLightBoxSlideIndex] = useState(0);
    const [isLightbox, openLightBox, closeLightBox] = useBooleanHandler();
    const { theme, style } = useBlockTheme(block);
    const { color, hasImage } = useContrastingPageColor();
    const { ratio, padding, perView, autoplay, effect } = theme;
    const { resolvedInsets, margin } = useDoubleNestedInsets(insets, padding);
    const width = useMemo(() => rawWidth, [!!rawWidth]);
    const total = block.images.length;
    const hasPagination = total > perView;
    const delay = autoplay * 1000;
    const restartDelay = Math.max(1000, 5000 - delay);
    const pagination = useMemo(() => {
        if (!hasPagination) {
            return false;
        }

        const borderColor = hasImage ? `border: 1px solid ${isLightColor(color) ? '#333' : '#eee'}` : '';

        return {
            renderBullet: (index, className) =>
                `<span style="background-color:${color};${borderColor};" class="${className} ${styles.bullet}"></span>`,
            clickable: true,
            clickableClass: styles.clickablePagination,
            bulletActiveClass: styles.activeBullet,
        };
    }, [hasPagination, color]);
    const autoplayOpts = useMemo(() => hasPagination && !!delay && { delay }, [hasPagination, delay]);

    const delayRestart = useThrottledCallback(restartDelay);
    const handleChange = useCallback(
        (swiper) => {
            if ((swiper.autoplay && swiper.autoplay.running) || !autoplayOpts) {
                return;
            }

            delayRestart(() => {
                swiper.autoplay && swiper.autoplay.start();
            });
        },
        [autoplayOpts]
    );

    const handleSlide = useCallback((index) => {
        setCurrentLightBoxSlideIndex(index);
        openLightBox();
    }, []);

    const forceUpdate = useForceUpdate();
    const hashItems = block.images.map(({ url, externalUrl }) => `${url}-${externalUrl}`).join(':');
    const key = sortable
        ? `${hashItems}-${perView}-${autoplay}-${effect}-${color}-${ratio}-${forceUpdate.count}-${width}`
        : 'swiper';

    return (
        <BlockBase
            lazy
            ref={ref}
            insets={resolvedInsets}
            sortable={sortable}
            {...cls([styles.root, styles[`perView-${perView}`]], rest)}
        >
            {total > 0 ? (
                <Swiper
                    key={key}
                    interactive={rest.interactive}
                    slideStyle={padding ? { padding: `0 ${margin}px` } : undefined}
                    slidesPerView={perView}
                    allowTouchMove={hasPagination && !rest.embedded}
                    flex
                    watchOverflow
                    autoHeight
                    loop
                    autoplay={autoplayOpts}
                    pagination={pagination}
                    fadeEffect={fade}
                    lazy={lazy}
                    effect={effect}
                    onSliderMove={handleChange}
                    onSwiper={setSwiper}
                    onUpdate={forceUpdate}
                >
                    {block.images.map((image, index) => (
                        <ImageSlide
                            key={image._id || `${image.url}-${index}`}
                            index={index}
                            image={image}
                            swiper={swiper}
                            style={style}
                            theme={theme}
                            onClick={handleSlide}
                            isViewMode={rest.isViewMode}
                            interactive={rest.interactive}
                        />
                    ))}
                </Swiper>
            ) : (
                <div className={styles.preview}>
                    <div className={cn(styles.placeholder, styles[ratio])} />
                </div>
            )}

            {isLightbox && (
                <LightBox activeIndex={currentLightBoxSlideIndex} images={block.images} onClose={closeLightBox} />
            )}
        </BlockBase>
    );
}

ImageGallery.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

ImageGallery.defaultProps = BlockBase.defaultProps;

ImageGallery.kind = ImageGalleryBlockKind;
