import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';

import { cls, isLightColor } from '@mssgme/helpers';
import { VideoGalleryBlockKind } from '@mssgme/shared';
import { LandingsPropTypes, Swiper } from '@mssgme/ui';
import { useBlockTheme, useContrastingPageColor } from '../../../hooks';
import { useDoubleNestedInsets } from '../../../UI';
import { BlockBase } from '../BlockBase';
import { VideoSlide } from './VideoSlide';

import styles from './VideoGallery.scss';

const fade = { crossFade: true };

export default function VideoGallery({ block, insets, style: baseStyle, ...rest }) {
    const [ref, { width }] = useMeasure();
    const [swiper, setSwiper] = useState(null);
    const {
        theme: { ratio, padding, autoplay, effect },
        style,
    } = useBlockTheme(block);
    const { color, hasImage } = useContrastingPageColor();
    const { resolvedInsets, margin } = useDoubleNestedInsets(insets, padding);
    const borderColor = hasImage && (isLightColor(color) ? '#333' : '#eee');
    const hasPagination = block.videos.length > 1;
    const pagination = useMemo(() => {
        return hasPagination
            ? {
                renderBullet: (index, className) =>
                    `<span style="background-color: ${color};${
                        borderColor ? `border: 1px solid ${borderColor}` : ''
                    };" class="${className + ' ' + styles.bullet}"></span>`,
                clickable: true,
                clickableClass: styles.clickablePagination,
                bulletActiveClass: styles.activeBullet,
            }
            : false;
    }, [hasPagination, color]);
    const delay = autoplay * 1000;
    const autoplayOpts = useMemo(() => hasPagination && !!delay && { delay }, [hasPagination, delay]);

    const stopAutoplay = useCallback(() => void swiper?.autoplay.stop(), []);

    return (
        <BlockBase lazy ref={ref} insets={resolvedInsets} {...cls(styles.root, rest)}>
            {block.videos.length === 0 ? (
                <div className={styles.preview}>
                    <div className={cn(styles.placeholder, styles[ratio])} />
                </div>
            ) : (
                <Swiper
                    key={`${block.videos.length}-${autoplay}-${effect}-${width}-${color}`}
                    flex
                    slideStyle={padding ? { padding: `0 ${margin}px` } : undefined}
                    interactive={rest.interactive}
                    allowTouchMove={hasPagination && !rest.embedded}
                    autoplay={autoplayOpts}
                    effect={effect}
                    pagination={pagination}
                    fadeEffect={fade}
                    onSliderMove={stopAutoplay}
                    onSwiper={setSwiper}
                >
                    {block.videos.map((video, index) =>
                        Object.assign(
                            ({ isActive }) => (
                                <VideoSlide
                                    url={video.url}
                                    annotation={video.annotation}
                                    style={style}
                                    ratio={ratio}
                                    isActive={isActive}
                                    onClick={stopAutoplay}
                                />
                            ),
                            { key: video._id || `${video.url}-${index}` }
                        )
                    )}
                </Swiper>
            )}
        </BlockBase>
    );
}

VideoGallery.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

VideoGallery.defaultProps = BlockBase.defaultProps;

VideoGallery.kind = VideoGalleryBlockKind;
