import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import React, { useEffect, useMemo } from 'react';

import { cls } from '@mssgme/helpers';
import { useBooleanHandler } from '@mssgme/hooks';

import styles from './VideoGallery.scss';

const YOUTUBE_TEST = /^https:\/\/(www.)?youtu\.?be/;
const YOUTUBE_ID_SPLITTER = /v\/|v=|youtu\.be\//;
const fixShorts = (url) => url && url.replace(/(youtu(?:\.be|be\.com))\/shorts/i, '$1/embed');
const extractCover = (url) => {
    const youtubeVideoId = url.match(YOUTUBE_TEST) ? url.split(YOUTUBE_ID_SPLITTER)[1]?.split(/[?&]/)[0] : null;

    if (youtubeVideoId) {
        return `https://i.ytimg.com/vi/${youtubeVideoId}/hqdefault.jpg`;
    }
};

export const VideoSlide = ({ url, annotation = '', isActive = true, ratio, ...rest }) => {
    const [isLoaded, setIsLoaded] = useBooleanHandler(false);
    const [isPlaying, play, stop] = useBooleanHandler(false);
    const [hasVideo, attributes] = useMemo(() => {
        const fixed = fixShorts(url) || '';
        const cover = extractCover(fixed);

        return [
            ReactPlayer.canPlay(fixed),
            {
                light: cover || true,
                url: fixed,
            },
        ];
    }, [url]);

    useEffect(() => {
        if (isLoaded) {
            play();
        }
    }, [isLoaded]);

    return (
        <div {...cls([styles.videoContainer, styles[ratio], { [styles.hasVideo]: hasVideo }], rest)}>
            {hasVideo && (
                <ReactPlayer
                    className={styles.player}
                    controls
                    playing={isPlaying && isActive}
                    width="100%"
                    height="100%"
                    onPause={stop}
                    onPlay={play}
                    onReady={setIsLoaded}
                    {...attributes}
                />
            )}
            {annotation && !isLoaded && !isPlaying && <div className={styles.annotation}>{annotation}</div>}
        </div>
    );
};

VideoSlide.propTypes = {
    url: PropTypes.string.isRequired,
    annotation: PropTypes.string,
    isActive: PropTypes.bool,
    ratio: PropTypes.string.isRequired,
    style: PropTypes.object,
    onClick: PropTypes.func.isRequired,
};
