import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Swiper as BaseSwiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade, EffectFlip, Pagination, Lazy, Navigation } from 'swiper';

import { cls } from '@mssgme/helpers';
import { renderNode } from '../../helpers';

import styles from './Swiper.scss';

// todo: direct "import 'swiper/swiper.scss'" mangles the identifiers and :global can't be used due to sass bug
//  should either update swiper (if styles import was changed in newer versions) or fix scss-build stack (downgrade?)
require('swiper/swiper.scss');
require('swiper/components/pagination/pagination.scss');
require('swiper/components/effect-flip/effect-flip.scss');
require('swiper/components/effect-fade/effect-fade.scss');

SwiperCore.use([Pagination, Lazy, Navigation, Autoplay, EffectFade, EffectFlip]);

export function Swiper({
    children,
    flex,
    perView = 1,
    initialSlide = 0,
    allowTouchMove = true,
    interactive = true,
    forcePerView,
    slideClass,
    pagination,
    slideStyle,
    ...rest
}) {
    const array = (Array.isArray(children) ? children : React.Children.toArray(children)).filter(Boolean);
    const count = array.length;
    const exceedsPerView = count > perView;
    const showPagination = pagination !== false;
    const hasPagination = exceedsPerView && showPagination;
    const slidesPerView = forcePerView || exceedsPerView ? perView : count;
    const paginationOptions = useMemo(() => {
        if (!hasPagination) {
            return false; // this will hide pagination AND disable swiping behavior
        }

        return showPagination
            ? { clickable: true, ...pagination } // pagination + swiping enabled
            : { el: null }; // this will hide pagination but preserve swiping behavior
    }, [hasPagination, showPagination, pagination]);

    return (
        <BaseSwiper
            key={`${exceedsPerView}-${showPagination}-${count}`}
            observer
            resizeObserver
            spaceBetween={0}
            slidesPerView={slidesPerView}
            allowTouchMove={allowTouchMove && exceedsPerView}
            initialSlide={initialSlide}
            pagination={paginationOptions}
            {...cls([styles.root, flex && styles.flex], rest)}
        >
            {array.map((child, index) => (
                <SwiperSlide
                    key={child.key}
                    style={renderNode(slideStyle, index)}
                    className={cn(styles.slide, !interactive && styles.nonInteractive, slideClass)}
                >
                    {child}
                </SwiperSlide>
            ))}
        </BaseSwiper>
    );
}

Swiper.propTypes = {
    children: PropTypes.any.isRequired,
    initialSlide: PropTypes.number,
    perView: PropTypes.number,
    forcePerView: PropTypes.bool,
    interactive: PropTypes.bool,
    allowTouchMove: PropTypes.bool,
    flex: PropTypes.bool,
    slideClass: PropTypes.string,
    slideStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};
