import cn from 'classnames';
import ReactPlayer from 'react-player';
import React from 'react';

import { VideoBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase, useBlockHorizontalInsetsPadding } from '../BlockBase';

import styles from './Video.scss';

const fixShorts = (url) => {
    return url && url.replace(/(youtu(?:.be|be.com))\/shorts/i, '$1/embed');
};

export default function Video({ block, block: { url }, insets, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const fixed = fixShorts(url);
    const hasVideo = ReactPlayer.canPlay(fixed);

    const blockInsets = useBlockHorizontalInsetsPadding(insets, theme.padding === 'yes');

    return (
        <BlockBase insets={blockInsets} {...cls(styles.wrapper, rest)}>
            <div className={cn(styles.root, styles[theme.ratio], { [styles.hasVideo]: hasVideo })} style={style}>
                {hasVideo && (
                    <ReactPlayer
                        className={styles.player}
                        light
                        playing
                        controls
                        url={fixed}
                        width="100%"
                        height="100%"
                    />
                )}
            </div>
        </BlockBase>
    );
}

Video.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Video.defaultProps = BlockBase.defaultProps;

Video.kind = VideoBlockKind;
