import { useCallback, useState } from 'react';

export function useForceUpdate() {
    const [count, setCount] = useState(0);
    const callback = useCallback(() => setCount((prev) => prev + 1), []);

    callback.count = count;

    return callback;
}
