import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { LoadingIndicator } from '@mssgme/ui';
import { useUploads, MediaSizes, useAction } from '../../../hooks';

import styles from './ImageGallery.scss';

export const ImageSlide = ({ index, image, style, theme: { ratio }, onClick, swiper, isViewMode, interactive }) => {
    const { Tag, tagAttributes } = useAction({ action: image.action, isViewMode, interactive });
    const { getMediaSizeUrl } = useUploads();
    const absoluteUrl = getMediaSizeUrl(image.url, MediaSizes.Large);
    const shouldFit = ratio !== 'original';

    const handleLoad = useCallback(() => void (swiper && swiper.updateAutoHeight()), [swiper]);
    const handleClick = interactive && image.action.type === 'fullImage' ? () => onClick(index) : null;

    useEffect(() => {
        if (swiper?.lazy?.initialImageLoaded) {
            swiper.lazy.loadInSlide(index);
        }
    }, [index, image]);

    return (
        <div
            className={cn(styles.imageBlock, {
                [styles.placeholder]: !absoluteUrl,
                [styles.fitOutside]: shouldFit,
                [styles[ratio]]: styles[ratio],
            })}
            style={style}
        >
            <Tag className={styles.imageContainer} onClick={handleClick} {...tagAttributes}>
                <div className={styles.image}>
                    <img
                        alt={image.annotation || 'Image'}
                        className="swiper-lazy"
                        data-src={absoluteUrl}
                        onLoad={handleLoad}
                    />
                </div>
                <div className={styles.preloader}>
                    <LoadingIndicator />
                </div>
            </Tag>

            {image.annotation && <div className={styles.annotation}>{image.annotation}</div>}
        </div>
    );
};

ImageSlide.propTypes = {
    index: PropTypes.number.isRequired,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        externalUrl: PropTypes.string,
        modal: PropTypes.bool,
        annotation: PropTypes.string,
        action: PropTypes.object,
    }).isRequired,
    theme: PropTypes.object.isRequired,
    style: PropTypes.object,
    onClick: PropTypes.func,
    swiper: PropTypes.object,
    isViewMode: PropTypes.bool,
    interactive: PropTypes.bool,
};
