import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useAsync, useMeasure } from 'react-use';
import { Gif } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';

import { giphy_public_key } from 'config';
import { GiphyBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './Giphy.scss';

export default function Giphy({ block, style: baseStyle, ...rest }) {
    const { style } = useBlockTheme(block);
    const [gif, setGif] = useState(null);
    const [ref, { width: containerWidth }] = useMeasure();
    const giphyFetch = useMemo(() => new GiphyFetch(giphy_public_key), []);
    const memoWidth = useMemo(() => containerWidth, [!!containerWidth]);
    const gifWidth = gif?.images.original.width > memoWidth ? memoWidth : gif?.images.original.width;
    const combined = useMemo(() => (baseStyle ? { ...style, ...baseStyle } : style), [style, baseStyle]);

    useAsync(async () => {
        const { data } = await giphyFetch.gif(block.giphyId);

        setGif(data);
    }, [block.giphyId]);

    return (
        <BlockBase style={combined} {...cls(styles.root, rest)}>
            <div className={cn({ [styles.content]: true, [styles.placeholder]: !gif })} ref={ref}>
                {gif && (
                    <Gif
                        gif={gif}
                        backgroundColor="transparent"
                        hideAttribution
                        width={gifWidth || 200}
                        borderRadius={16}
                        noLink
                    />
                )}
            </div>
        </BlockBase>
    );
}

Giphy.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Giphy.defaultProps = BlockBase.defaultProps;

Giphy.kind = GiphyBlockKind;
